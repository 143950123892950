/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps, ReactElement } from 'react';
import clsx from 'clsx';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { BaseError } from '../Errors';
import { Popover } from '../../common/Popover';

interface RadioProps<T extends FieldValues> extends ComponentProps<'input'> {
  label?: string
  helpText?: string | ReactElement
  value: string
  id: string
  path: Path<T>
}

export type RadioOption<T extends FieldValues> = Omit<RadioProps<T>, 'path'>;

export function Radio<T extends FieldValues>({
  id, label, value, path, helpText, ...inputProps
}: RadioProps<T>) {
  const { register } = useFormContext<T>();

  return (
    <div className={label && 'form-check'}>
      <input
        {...inputProps}
        {...register(path)}
        className="form-check-input"
        type="radio"
        id={id}
        value={value}
      />
      {label && (
        <label htmlFor={id} className="form-check-label">
          {label}
          {helpText && (
            <Popover
              id={`${id}-help`}
              width={362}
              trigger="hover"
              renderTrigger={() => <img className="ms-2" src="/static/img/icons/help.svg" alt="Más información" />}
              renderContent={() => (
                <div className="d-flex align-items-start gap-2 p-1">
                  <img src="/static/img/icons/help-light.svg" width={48} alt="" />
                  <div>
                    {helpText}
                  </div>
                </div>
              )}
            />
          )}
        </label>
      )}
    </div>
  );
}

interface RadioButtonsProps<T extends FieldValues> {
  label?: string | ReactElement
  visuallyHiddenLabel?: boolean
  helpText?: string | ReactElement
  options: Omit<RadioProps<T>, 'path'>[]
  optionsDirection?: 'horizontal' | 'vertical'
  path: Path<T>
  disabled?: boolean
}

export function RadioField<T extends FieldValues>({
  label,
  path,
  options,
  helpText,
  disabled,
  optionsDirection = 'horizontal',
  visuallyHiddenLabel = false,
}: RadioButtonsProps<T>) {
  const { getFieldState } = useFormContext<T>();
  const { error } = getFieldState(path);

  return (
    <fieldset>
      <legend className={clsx(visuallyHiddenLabel && 'visually-hidden', disabled ? 'text-disabled' : 'text-primary', 'fs-sm fw-bold mb-2 d-inline-block')}>
        {label}
        {helpText && (
          <Popover
            id={`${path}-help`}
            width={362}
            trigger="hover"
            renderTrigger={() => (
              <img
                style={{ pointerEvents: disabled ? 'none' : 'all' }}
                className="ms-2"
                src={disabled ? '/static/img/icons/help-disabled.svg' : '/static/img/icons/help.svg'}
                alt="Más información"
              />
            )}
            renderContent={() => (
              <div className="d-flex align-items-start gap-2 p-1">
                <img src="/static/img/icons/help-light.svg" width={48} alt="" />
                <div>
                  {helpText}
                </div>
              </div>
            )}
          />
        )}
      </legend>

      <div className={clsx('d-flex w-100', optionsDirection === 'vertical' ? 'flex-column gap-1' : 'gap-5')}>
        {options.map(({ id, ...props }) => (
          <Radio disabled={disabled} key={`${id}-${path}`} id={`${id}-${path}`} path={path} {...props} />)
        )}
      </div>

      <BaseError message={error?.message} />
    </fieldset>
  );
}
