/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Controller, FieldValues, Path, PathValue, useFormContext,
} from 'react-hook-form';
import ReactSelect, { SingleValue } from 'react-select';
import clsx from 'clsx';
import isEmpty from 'lodash.isempty';
import { BaseError } from '../Errors';
import { getErrorClassName } from '../../../utils/forms';

export interface SelectOption<T extends FieldValues = Record<string, unknown>> {
  value: PathValue<T, Path<T>>
  label: string
}

interface SelectFieldProps<T extends FieldValues> {
  id: string
  options: SelectOption<T>[]
  path: Path<T>
  formControlClassName?: string
  onChange?: (option: SingleValue<SelectOption<T>>) => void
  label?: string
  renderLabel?: () => JSX.Element
  placeholder?: string
  hideErrorMessage?: boolean
  isSearchable?: boolean
  anchorBehavior?: 'body' | 'element'
}

export function SelectField<T extends FieldValues>({
  path,
  options,
  id,
  formControlClassName,
  label,
  renderLabel,
  hideErrorMessage,
  placeholder,
  onChange,
  isSearchable,
  anchorBehavior = 'body',
}: SelectFieldProps<T>) {
  const { control, getFieldState } = useFormContext<T>();
  const { error } = getFieldState(path);

  // Uses `body` element as anchor for rendering floating options list
  // Else, uses parent element as anchor
  const menuPortalTarget = anchorBehavior === 'body' ? document.body : null;

  return (
    <div className="w-100">
      {label && <label htmlFor={id} className="fs-sm fw-bold text-primary w-100 mb-2">{label}</label>}
      {renderLabel && <label htmlFor={id} className="fs-sm fw-bold text-primary w-100 mb-2">{renderLabel()}</label>}
      <Controller
        name={path}
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption<T>>
            {...field}
            onChange={(selected) => {
              // Runs custom onChange given via props
              onChange?.(selected);

              // Runs form handler onChange
              field.onChange(selected);
            }}
            value={isEmpty(field.value) ? null : field.value}
            className="react-select-container"
            classNamePrefix="react-select"
            classNames={{ control: () => clsx('form-select', formControlClassName, error && getErrorClassName()) }}
            isSearchable={isSearchable}
            isMulti={false}
            isClearable={false}
            noOptionsMessage={() => <span>Sin opciones</span>}
            components={{
              DropdownIndicator: null,
            }}
            options={options}
            placeholder={placeholder}
            menuPortalTarget={menuPortalTarget}
          />
        )}
      />

      {!hideErrorMessage && <BaseError message={error?.message} />}
    </div>
  );
}
