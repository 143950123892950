export function getElementByName<T extends Element = HTMLInputElement>(
  name: string, parent?: Element): T | null {
  const selector = `[name="${name}"]`;

  if (parent) {
    return parent.querySelector(selector);
  }

  return document.querySelector(selector);
}

export function getAllElementsByName<T extends Element = HTMLInputElement>(
  name: string, parent?: Element
): NodeListOf<T> | null {
  const selector = `[name="${name}"]`;

  if (parent) {
    return parent.querySelectorAll(selector);
  }

  return document.querySelectorAll(selector);
}

export function getRadioInputValue(radioInputs: NodeListOf<HTMLInputElement>) {
  return [...radioInputs].find((radio) => radio.checked)?.value ?? '';
}

export function getSubmitButton(parent?: Element): HTMLButtonElement | null {
  if (parent) {
    return parent.querySelector<HTMLButtonElement>('[type="submit"]');
  }

  return document.querySelector<HTMLButtonElement>('[type="submit"]');
}

export function disableButton(buttonElement: HTMLButtonElement) {
  buttonElement.classList.add('btn-disabled');
  buttonElement.setAttribute('disabled', 'true');
}

export function enableButton(buttonElement: HTMLButtonElement) {
  buttonElement.classList.remove('btn-disabled');
  buttonElement.removeAttribute('disabled');
}

export function getErrorClassName() {
  return 'is-invalid';
}

export function getCSRF() {
  const CSRF_NAME_SELECTOR = '[name=csrfmiddlewaretoken]';
  const csrfInput = document.querySelector<HTMLInputElement>(CSRF_NAME_SELECTOR);

  if (!csrfInput) {
    throw new Error('CSRF Input element does not exist');
  }

  return {
    name: csrfInput.name,
    value: csrfInput.value,
  };
}

interface InjectCleanedFieldToFormParams {
  form: HTMLFormElement
  name: string
  value: unknown
}

/**
 * Takes a form, target field name and a cleaned value and tries to inject it into the given form
 * at the provided field name.
 *
 * It is useful when we need to handle validations and form state using React Hook Forms but also
 * we have to dispatch a native submit of the form.
 */
export function injectCleanedFieldToForm({ form, name, value }: InjectCleanedFieldToFormParams) {
  const field = form.querySelector<HTMLInputElement>(`[name="${name}"]`);

  if (!field) {
    throw new Error('Cannot get form field to inject cleaned value');
  }

  field.value = String(value);
}
