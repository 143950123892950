export const themeColors = {
  primary: '#0c4497',
  subtitle: '#333840',
  skyBlue: '#a7ddf6',
  redMiddle: '#f0a79e',
  redDark: '#a5110c',
  gray: {
    600: '#6c757d',
    500: '#adb5bd',
    400: '#ced4da',
  },

  // Colors outside of theme colors. Only dedicated to custom chart colors
  charts: {
    beige: '#aa8641',
    beigeLight: '#fcf2cc',
  },
};
