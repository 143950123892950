import React from 'react';
import { TooltipProps } from 'recharts';
import { formatMoney } from '../../../utils/formatters';
import { FinancialDataPoint } from '../types';

interface MoneyTooltipProps extends TooltipProps<number, string> {
  background: string
  color: string
}

export function MoneyTooltip({ payload, background, color }: MoneyTooltipProps) {
  const [current] = payload ?? [];

  if (!current) {
    return null;
  }

  const dataPoint = current.payload as FinancialDataPoint;

  return (
    <div style={{
      background,
      color,
      paddingInline: 8,
      paddingBlock: 4,
      borderRadius: 4,
    }}
    >
      {formatMoney(dataPoint.value)}
    </div>
  );
}
