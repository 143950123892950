import React from 'react';

interface ShowPasswordButtonProps {
  onClick: () => void
  isShowing: boolean
}

export function ShowPasswordButton({ onClick, isShowing }: ShowPasswordButtonProps) {
  return (
    <button type="button" className="btn btn-form-adornment" onClick={onClick} aria-label={isShowing ? 'Ocultar contraseña' : 'Mostrar contraseña'}>
      <img src={isShowing ? '/static/img/icons/visibility-off.svg' : '/static/img/icons/visibility.svg'} alt="" />
    </button>
  );
}
