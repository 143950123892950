import React from 'react';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DateTime } from 'luxon';
import { useQuery } from '@tanstack/react-query';
import { themeColors } from './common';
import { formatMoney } from '../../utils/formatters';
import { ChartCard } from './components/ChartCard';
import { MoneyTooltip } from './components/MoneyTooltip';
import { JsonPropsType } from '../../component-loader';
import { getRecommendationChartData } from './services';
import { LoadingSpinner } from '../common';
import { WithQueryClient } from '../common/WithQueryClient';
import { ErrorMessage } from './components/ErrorMessage';

interface InvestmentEvolutionChartProps {
  profile?: string
  productId: number
  url: string
}

const roundDomain = (value: number) => [
  Math.floor(value / 100_000) * 100_000,
];

function InvestmentEvolutionChart({ productId, profile, url }: InvestmentEvolutionChartProps) {
  const {
    data: response, isSuccess, isLoading, isError,
  } = useQuery({
    queryFn: () => getRecommendationChartData({ productId, profile: profile ?? '' }),
    queryKey: ['INVESTMENT_EVOLUTION', profile, productId],
    refetchOnWindowFocus: false,
  });

  const chartHeight = 250;

  const chartMargins = {
    top: 24,
    right: 48,
    left: 48,
  };

  return (
    <ChartCard headerText="Evolución de una inversión de $1.000.000" textLink="Ir a la ficha" url={url}>
      <div className="mb-4" style={{ height: chartHeight }}>
        {isError && <ErrorMessage />}
        {isLoading && <LoadingSpinner className="h-100" size={108} borderWidth={4} color="primary" />}
        {isSuccess && response && (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              margin={chartMargins}
              data={response.data.graph_data}
            >
              <XAxis
                interval={12}
                tickMargin={8}
                tick={{ fill: themeColors.subtitle }}
                tickFormatter={(d: string) => String(DateTime.fromFormat(d, 'yyyy-MM').year)}
                stroke={themeColors.subtitle}
                dataKey="date"
                tickLine={false}
                fontSize="14"
              />
              <YAxis
                tickCount={8}
                domain={[
                  (dataMin: number) => roundDomain(dataMin),
                  (dataMax: number) => roundDomain(dataMax)]}
                tickLine={false}
                tickFormatter={(v: number) => formatMoney(v)}
                tick={{ fill: themeColors.subtitle }}
                stroke={themeColors.gray[400]}
                axisLine={false}
              />

              <Line strokeWidth={2} dot={false} type="monotone" dataKey="value" stroke={themeColors.primary} />
              <Tooltip
                cursor={false}
                content={(
                  <MoneyTooltip
                    background={themeColors.skyBlue}
                    color={themeColors.primary}
                  />
                )}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </ChartCard>
  );
}

interface InvestmetnEvolutionChartWrapperProps {
  profile?: string
  productId: number
  url: string
}

export function InvestmentEvolutionChartWrapper(props: JsonPropsType) {
  const { profile, productId, url } = props.jsonObject as InvestmetnEvolutionChartWrapperProps;

  return (
    <WithQueryClient>
      <InvestmentEvolutionChart profile={profile} productId={productId} url={url} />
    </WithQueryClient>
  );
}
