/* eslint-disable react/require-default-props */
import React from 'react';

interface BaseErrorProps {
  message?: string
}

export function BaseError({ message }: BaseErrorProps) {
  if (!message) {
    return null;
  }

  return (
    <span className="invalid-feedback d-block">{message}</span>
  );
}
