import { api } from '../../utils/api';
import { FinancialDataPoint } from './types';

interface GetRecommendationChartDataParams {
  profile: string
  productId: number
}

interface GetRecommendationChartDataResponse {
  product: number,
  graph_data: FinancialDataPoint[]
}

export async function getRecommendationChartData({
  profile,
  productId,
}: GetRecommendationChartDataParams) {
  const EXPECTED_RETURNS_ENDPOINT = `recommendations/graph-data/${productId}`;
  return api.get<GetRecommendationChartDataResponse>(
    EXPECTED_RETURNS_ENDPOINT, { params: { profile } });
}
