/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import clsx from 'clsx';
import {
  QueryClient, QueryClientProvider, useMutation,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getCSRF } from '../../../utils/forms';
import { InputField } from '../../Forms/Fields';
import { ShowPasswordButton } from '../../Forms/ShowPasswordButton';
import { BackendErrorsList, ErrorCode } from './BackendErrorsList';
import { ValidatePasswordResponse, passwordChangeService } from './passwordChangeService';
import { useDebounce } from '../../../hooks/useDebounce';
import { PASSWORD_VALIDATION_MESSAGES } from './validations';

const passwordChangeValidationSchema = z.object({
  new_password1: z.string().nonempty('Este campo es requerido'),
  new_password2: z.string().nonempty('Este campo es requerido'),
})
  .refine(({ new_password1, new_password2 }) => (new_password1 === new_password2),
    {
      message: PASSWORD_VALIDATION_MESSAGES.confirmationSameAsPassword,
      path: ['new_password2'],
    });

type PasswordChangeValues = z.infer<typeof passwordChangeValidationSchema>;
type PasswordField = 'new' | 'confirmation';

const useValidatePassswordAsync = (password: string) => {
  const INITIAL_ERRORS: ErrorCode[] = [
    'password_entirely_numeric',
    'password_too_common',
    'password_too_short',
    'password_too_similar',
  ];
  const DEBOUNCE_TIME = 1; // in seconds

  // `UIDB64_POSITION` is hardcoded just taking the current location pathname and counting
  // from until `<uidb64>` occurrence
  // `/accounts/reset/<uidb64>/set-password`
  const UIDB64_POSITION = 3;
  const userId = window.location.pathname.split('/')[UIDB64_POSITION];

  const [asyncValidationErrors, setAsyncValidationErrors] = useState<ErrorCode[]>(INITIAL_ERRORS);
  const debouncedPassword = useDebounce<string>(password, DEBOUNCE_TIME);

  const { mutate: dispatchValidatePasswordAsync } = useMutation({
    mutationFn: async () => passwordChangeService
      .validate(userId, { new_password: debouncedPassword }),
    onSuccess: ({ new_password }) => setAsyncValidationErrors(new_password),
    onError: (err: AxiosError<ValidatePasswordResponse>) => setAsyncValidationErrors(
      err.response?.data.new_password ?? []
    ),
  });

  useEffect(() => {
    if (debouncedPassword !== '') {
      dispatchValidatePasswordAsync();
    }
  }, [debouncedPassword]);

  return asyncValidationErrors;
};

function PasswordChangeForm() {
  const { name: CSRFName, value: CSRFValue } = getCSRF();
  const methods = useForm<PasswordChangeValues>({
    defaultValues: {
      new_password1: '',
      new_password2: '',
    },
    resolver: zodResolver(passwordChangeValidationSchema),
  });

  const [showPasswords, setShowPasswords] = useState<Record<PasswordField, boolean>>({
    confirmation: false,
    new: false,
  });

  const formRef = useRef<HTMLFormElement>(null);

  const handleToggleShowPassword = (field: PasswordField) => {
    setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
  };

  const handleValidateErrors = () => methods.formState.errors;

  const { new_password1, new_password2 } = methods.watch();
  const asyncErrors = useValidatePassswordAsync(new_password1);

  const hasEmptyFields = [new_password1, new_password2]
    .some((v) => v?.length === 0);
  const hasErrors = asyncErrors.length !== 0;

  const isSumitDisabled = hasErrors || hasEmptyFields;
  const onSubmit = () => formRef?.current?.submit();

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        className="mb-6"
        action=""
        method="post"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="d-flex flex-column align-items-center gap-4">
          <input type="hidden" name={CSRFName} value={CSRFValue} />

          <InputField<PasswordChangeValues>
            id="new_password1"
            path="new_password1"
            type={showPasswords.new ? 'text' : 'password'}
            placeholder="Clave nueva"
            label="Ingresa tu nueva clave"
            endAdornment={() => (
              <ShowPasswordButton
                isShowing={showPasswords.new}
                onClick={() => handleToggleShowPassword('new')}
              />
            )}
          />

          <BackendErrorsList errors={asyncErrors} />

          <InputField<PasswordChangeValues>
            id="new_password2"
            path="new_password2"
            type={showPasswords.confirmation ? 'text' : 'password'}
            placeholder="Clave nueva"
            label="Confirma tu nueva clave"
            endAdornment={() => (
              <ShowPasswordButton
                isShowing={showPasswords.confirmation}
                onClick={() => handleToggleShowPassword('confirmation')}
              />
            )}
          />

          <button
            disabled={isSumitDisabled}
            type="submit"
            onClick={handleValidateErrors}
            className={clsx('btn btn-primary w-100 rounded-2 p-2 fw-bold', isSumitDisabled && 'btn-disabled')}
          >
            Cambiar mi clave
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

const queryClient = new QueryClient();

export function PasswordChangeFormWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <PasswordChangeForm />
    </QueryClientProvider>
  );
}
